export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "sprunki romantic",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://www.youtube.com/embed/-YSi2LhZ__Q?si=i77k0_e6vCVkxx50",
    domain: "sprunki-romantic.org",
    gaId: "G-8ZJH86ZD4R",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
